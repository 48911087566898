import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Clippings = ({lang = 'en'}) => {
    const query = useStaticQuery(graphql`query {
        clippings: allStrapiNotices {
            edges {
                node {
                    link
                    description
                    media {
                        name
                        media {
                            name
                            alternativeText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 280
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    }
                    locale
                }
            }
        }
        helpers: allMarkdownRemark ( filter: { frontmatter: { name: { eq: "helpers" } } }) {
            edges {
                node {
                    frontmatter {
                        lang
                        clippings {
                            title
                        }
                    }
                }
            }
        }
    }`)
    const clippings = query.clippings.edges.filter(item => item.node.locale === lang)
    const helpers = query.helpers.edges.filter((item) => item.node.frontmatter.lang === lang)[0].node.frontmatter

    const [selectedClippingIndex, setSelectedClippingIndex] = useState(0)
    const [nextClippingAvailable, setNextClippingAvailable] = useState(true)
    const [prevClippingAvailable, setPrevClippingAvailable] = useState(true)

    const nextClipping = () => {
        if (selectedClippingIndex === (clippings.length - 1)) {
            setSelectedClippingIndex(0)
            return
        }

        setSelectedClippingIndex(selectedClippingIndex + 1)
    }

    const prevClipping = () => {
        if (selectedClippingIndex === 0) {
            setSelectedClippingIndex(clippings.length - 1)
            return
        }

        setSelectedClippingIndex(selectedClippingIndex - 1)
    }

    const selectClippingIndex = (index) => {
        setSelectedClippingIndex(index)
        //setPrevOpinionAvailable(index > 0)
        //setNextOpinionAvailable((clippings.length - 1) > index)
    }

    return clippings.length > 0 && <div className="main__section main__section--100">
        <div className="container container--gray">
            <h2>{helpers.clippings.title}</h2>
            <div className="carrousel">
                <div className="carrousel__content">
                    <div className="carrousel__content__button">
                        <button className="button-icon button-icon--secondary button-icon--lg" disabled={!prevClippingAvailable} onClick={prevClipping}>
                            <i className="icon-arrow-left"></i>
                        </button>
                    </div>

                    <div className="carrousel__content__box">
                        {clippings.map((item, index) => {
                            return <div className={`box__testimonial ${selectedClippingIndex === index ? 'box__testimonial--show' : ''}`} key={index}>
                                <div className="box__testimonial__image">
                                    <GatsbyImage alt={item.node.media.media.alternativeText} image={getImage(item.node.media.media.localFile)} style={{maxHeight: 224, maxWidth: 280}} />
                                </div>
                                <div className="box__testimonial__text">
                                    <div className="text__customer">
                                        “{item.node.description}”
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>

                    <div className="carrousel__content__button">
                        <button className="button-icon button-icon--secondary button-icon--lg" disabled={!nextClippingAvailable} onClick={nextClipping}>
                            <i className="icon-arrow-right"></i>
                        </button>
                    </div>
                </div>
                <div className="carrousel__dots">
                    {clippings.map((item, index) => {
                        return <a className={`dots__dot ${selectedClippingIndex === index ? 'dots__dot--active' : ''}`} key={index} onClick={() => selectClippingIndex(index)}/>
                    })}
                </div>
            </div>
        </div>
    </div>
}

export default Clippings
